<template>
  <a class="button video-meeting__call-concierge" @click="showForm">
    <i class="fas fa-user-headset"></i>
    {{ __('video_meeting.message_concierge.buttons.message_concierge') }}
  </a>
</template>

<script>
import { modalOpen, modalPush } from '~/modal';
import MessageConciergeForm from './MessageConciergeForm.vue';

export default {
  name: 'MessageConciergeButton',
  methods: {
    showForm() {
      modalPush(
        MessageConciergeForm,
        {
          size: 'small',
        },
      );
      modalOpen();
    },
  },
};
</script>
