<template>
  <li
    class="video-meeting__attendee"
    :class="{
      'video-meeting__attendee--arrived': attendee.pivot.has_attended,
      'video-meeting__attendee--connecting': status === 'connecting',
      'video-meeting__attendee--online': status === 'online',
    }"
  >
    <ContactAvatar :contact="attendee" size="medium" />
    <div class="video-meeting__attendee-details">
      <div class="video-meeting__attendee-name">
        {{ attendee.first_name }}
        {{ attendee.last_name }}
      </div>
      <div class="video-meeting__attendee-position-company">
        {{ attendee.company.display_name }} @ {{ attendee.company.display_name }}
      </div>
      <div class="video-meeting__attendee-status">
        <i class="fas fa-circle"></i>&nbsp;
        <span v-if="! attendee.pivot.has_attended">{{ __('video_meeting.waiting_join') }}</span>
        <span v-else>
          {{ __(`video_meeting.${status}`) }}
        </span>
        <span
          v-if="status === 'offline' && this.attendeeStatus?.last_active"
        >
          {{__('video_meeting.last_seen', {last_seen: lastSeen})}}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import moment from 'moment';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';

export default {
  name: 'VideoMeetingAttendee',
  props: {
    attendee: {
      type: Object,
      required: true,
    },
    attendeeStatus: {
      type: Object,
    },
  },
  components: { ContactAvatar },
  computed: {
    status() {
      if (!this.attendeeStatus) {
        return 'offline';
      }

      if (!this.attendeeStatus.last_active) {
        return 'offline';
      }

      const now = new Date().getTime();
      // cut off is 46 seconds - which is just over 3 heartbeats
      // as the signal is every 15 seconds
      if (now - 46000 > this.attendeeStatus.last_active) {
        return 'offline';
      }

      return this.attendeeStatus.status;
    },
    lastSeen() {
      return moment(this.attendeeStatus.last_active).fromNow();
    },
  },
  methods: {
    moment,
  },
};
</script>
