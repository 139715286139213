<template>

  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >

    <template #header>
      <h3 class="base-modal__title">Meeting invitation</h3>
    </template>

    <template #subheader>
      <ContactModalHeader
        v-if="contact.id"
        :key="contact.id"
        :contact="contact"
      />

      <div
        v-if="invite.policy?.can_accept || invite.policy?.can_forward || invite.policy?.can_decline"
        class="button-group"
      >
        <a
          class="button button-primary"
          v-if="invite.policy?.can_accept"
          :href="`#/invites/${invite.id}/accept`"
          >
          {{ __('expanded_contact.buttons.accept_invite') }}
        </a>
        <a
          class="button button--secondary"
          v-if="invite.policy?.can_forward"
          :href="`#/invites/${invite.id}/forward`"
          >
          {{ __('expanded_contact.buttons.forward_to_colleague') }}
        </a>
        <a
          class="button button--tertiary"
          v-if="invite.policy?.can_decline"
          :href="`#/invites/${invite.id}/decline`"
          >
          {{ __('expanded_contact.buttons.decline_invite') }}
        </a>
      </div>
    </template>

    <div class="contact-conversation" v-if="contact.id && !errored">
      <MeetingConversation
        ref="conversation"
        :meetingInviteId="inviteId"
        @loaded="loaded = true"
        :isBeingViewed="loaded"
      />
    </div>

    <template v-if="(!loaded || !contact.id) && !errored">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template #footer>
      <FrontendTextInput
        v-if="loaded && invite.messageable"
        id="newMessage"
        key="newMessage"
        name="newMessage"
        v-model="newMessage"
        :actionButton="true"
        actionButtonIcon="fas fa-paper-plane"
        :max="500"
        type="textarea"
        hideCounter
        preventResize
        autosize
        @actionButtonClicked="sendChat"
        :placeholder="__('chat_visible')"
      />
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store, user } from '~/Frontend/store';
import MeetingConversation from '~/Components/Frontend/Networking/MeetingConversation.vue';

export default {
  props: {
    inviteId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      errored: false,
      loaded: false,
      contact: {},
      invite: {},
      activeContactIsSender: false,
      activeContactIsRecipient: false,
      notifications: [],
      newMessage: '',
    };
  },
  components: {
    MeetingConversation,
  },
  methods: {
    loadData() {
      axios
        .get(`/${this.eventEdition.slug}/meeting-invites/${this.inviteId}`)
        .then((response) => {
          this.invite = response.data.invite;
          if (this.activeContact.id === response.data.invite.recipient_id) {
            this.contact = response.data.invite.sender;
            this.activeContactIsSender = true;
          } else {
            this.contact = response.data.invite.recipient;
            this.activeContactIsRecipient = true;
          }
        })
        .catch((response) => {
          this.errored = true;
          this.errorMessage = response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: this.errorMessage ?? "Couldn't load contact information.",
            icon: 'fa-circle-info',
          });
        });
    },
    sendChat() {
      this.newMessage = this.newMessage.trim();

      if (this.newMessage.length === 0) {
        return;
      }

      const endpoint = `/${this.eventEdition.slug}/meeting-invites/${this.inviteId}/conversation`;
      axios.post(endpoint, { new_message: this.newMessage })
        .then(() => {
          this.newMessage = '';
          this.$refs.conversation.update();
          this.emitter.emit('inboxMessages.new');
        }).catch((response) => {
          this.errored = true;
          this.errorMessage = response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: this.errorMessage ?? "Couldn't load contact information.",
            icon: 'fa-circle-info',
          });
        });
    },
  },
  computed: {
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
