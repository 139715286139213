<template>
  <Transition :name="isFullPage ? 't-shrink-fade' : ''" mode="out-in">
    <component
      v-if="currentRoute"
        :is="currentView"
        v-bind="parameters"
        :key="currentPath"
        @closed="clearHash"
      ></component>
  </Transition>
</template>

<script>
/**
 * @component ModalRouter
 *
 * A component to open a modal from a url or anchor
 *
 * Works using the url-fragment to define frontend-routing, a pattern inspired by this
 * @link https://vuejs.org/guide/scaling-up/routing.html
 *
 * Routes are defined using similar to Laravel syntax, where route-parameters can be
 * specified with {param} syntax
 *
 * @example /companies/{company}/products
 *
 * When a route is accessed the Router will set its component as
 * route's view and pass route parameters to the component as props
 */
import CatalogueEntryPreviewModal from '~/Components/Frontend/CatalogueEntry/CatalogueEntryPreviewModal.vue';
import CompanyBookMeetingModal from '~/Components/Frontend/CRM/CompanyBookMeetingModal.vue';
import CompanyPreviewModal from '~/Components/Frontend/CRM/CompanyPreviewModal.vue';
import ConciergeContactModal from '~/Components/Frontend/ConciergeContactModal.vue';
import ContactProfileModal from '~/Components/Frontend/CRM/Components/ContactProfileModal.vue';
import DeclineMeetingInviteModal from '~/Components/Frontend/Networking/DeclineMeetingInviteModal.vue';
import ForwardMeetingInviteModal from '~/Components/Frontend/Networking/ForwardMeetingInviteModal.vue';
import LegalPage from '~/Components/Frontend/LegalPage.vue';
import MeetingInviteModal from '~/Components/Frontend/Networking/MeetingInviteModal.vue';
import MeetingModal from '~/Components/Frontend/Networking/MeetingModal.vue';
import AddColleaguesToMeetingModal from '~/Components/Frontend/Networking/AddColleaguesToMeetingModal.vue';
import CancelMeetingModal from '~/Components/Frontend/Networking/CancelMeetingModal.vue';
import OtherBookingModal from '~/Components/Frontend/Schedule/OtherBookingModal.vue';
import PrivacyPolicy from '~/Components/Frontend/PrivacyPolicy.vue';
import ProfileSelectModal from '~/Components/Frontend/Profile/ContactProfile/ProfileSelect/ProfileSelectModal.vue';
import RateMeetingModal from '~/Components/Frontend/Networking/RateMeetingModal.vue';
import ScheduleMeetingModal from '~/Components/Frontend/Networking/ScheduleMeetingModal.vue';
import SendMeetingInviteModal from '~/Components/Frontend/Networking/SendMeetingInviteModal.vue';
import SessionPreviewModal from '~/Components/Frontend/Conference/SessionPreviewModal.vue';
import UpdateAvailabilityModal from '~/Components/Frontend/Schedule/UpdateAvailabilityModal.vue';
import WithdrawMeetingInviteModal from '~/Components/Frontend/Networking/WithdrawMeetingInviteModal.vue';
import ContactConversationModal from '~/Components/Frontend/CRM/ContactConversationModal.vue';
import PreviewContactCardModal from '~/Components/Frontend/Profile/Modals/PreviewContactCardModal.vue';
import PreviewCompanyCardModal from '~/Components/Frontend/Profile/Modals/PreviewCompanyCardModal.vue';
import ProfileFullPageModal from '~/Components/Frontend/Profile/ProfileFullPageModal.vue';
import PurchaseUpgradeModal from '~/Components/Frontend/CRM/PurchaseUpgradeModal.vue';
import VideoPlayModal from '~/Components/Frontend/Networking/Video/VideoPlayModal.vue';
import ShareScheduleModal from '~/Components/Frontend/Schedule/ShareScheduleModal.vue';

import { user } from '../../Frontend/store';

const routes = {
  '/contacts/{contactId}/profile': ContactProfileModal,
  '/concierge-contact': ConciergeContactModal,
  '/profile-select': ProfileSelectModal,
  '/update-availability': UpdateAvailabilityModal,
  '/other-bookings/{otherBookingId}': OtherBookingModal,
  '/other-bookings-create/{datetimeRange}': OtherBookingModal,
  '/companies/{companyId}': CompanyPreviewModal,
  '/companies/{companyId}/book-meeting': CompanyBookMeetingModal,
  '/companies/{companyId}/book-meeting/{catalogueEntryId}': CompanyBookMeetingModal,
  '/sessions/{sessionId}/show': SessionPreviewModal,
  '/contacts/{contactId}/send-invite': SendMeetingInviteModal,
  '/contacts/{contactId}/conversation': ContactConversationModal,
  '/meetings/{meetingId}/add-colleague': AddColleaguesToMeetingModal,
  '/meetings/{meetingId}/cancel': CancelMeetingModal,
  '/meetings/{meetingId}/reschedule': ScheduleMeetingModal,
  '/meetings/{meetingId}/details': MeetingModal,
  '/meetings/{meetingId}/details/{initialTab}': MeetingModal,
  '/invites/{inviteId}/accept': ScheduleMeetingModal,
  '/invites/{inviteId}/forward': ForwardMeetingInviteModal,
  '/invites/{inviteId}/show': MeetingInviteModal,
  '/invites/{inviteId}/decline': DeclineMeetingInviteModal,
  '/invites/{inviteId}/withdraw': WithdrawMeetingInviteModal,
  '/meetings/{meetingId}/rate': RateMeetingModal,
  '/catalogue-entries/{catalogueEntryId}/preview': CatalogueEntryPreviewModal,
  '/profile/preview': PreviewContactCardModal,
  '/profile/company-preview': PreviewCompanyCardModal,
  '/purchase-upgrade/{purchaseItemId}': PurchaseUpgradeModal,
  // '/conferences/{sessionId}/ask-a-question': AskAQuestionModal,
  '/video/{videoId}/preview': VideoPlayModal,
  '/my-schedule/share': ShareScheduleModal,
};

const publicRoutes = {
  '/companies/{companyId}': CompanyPreviewModal,
  '/catalogue-entries/{catalogueEntryId}/preview': CatalogueEntryPreviewModal,
  '/legal-pages/{slug}': LegalPage,
  '/privacy-policy': PrivacyPolicy,
  '/iframe/sessions/{sessionId}/preview': SessionPreviewModal,
};

const fullPagesRoutes = {
  '/my-account/{initialTab}': ProfileFullPageModal,
};

/**
 * Replaces the route-parameter blocks {$param} with wildcard capture groups
 * and returns as a RegEx Pattern
 *
 * @param {string} route
 * @return {RegExp}
 * */
function routeToPreg(route) {
  return new RegExp(`^#${route.replaceAll(/{(.*?)}/g, '([^/]*?)')}$`);
}

/**
 * Returns the names of the route-parameters
 *
 * @param {string} route
 * @return {array}
 * */
function routeToNames(route) {
  return [...route.matchAll(/{(.*?)}/g)].map((match) => match[1]);
}

export default {
  props: {
    isFullPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentPath: '',
    };
  },
  computed: {
    compiledRoutes() {
      let permittedRoutes = publicRoutes;
      if (user.user) {
        permittedRoutes = {
          ...publicRoutes,
          ...routes,
        };
      }
      if (this.isFullPage) {
        permittedRoutes = user.user ? fullPagesRoutes : {};
      }
      return Object.entries(permittedRoutes).map(([route, view]) => ({
        route, view, preg: routeToPreg(route),
      }));
    },
    currentRoute() {
      return this.compiledRoutes.find((route) => this.currentPath.match(route.preg));
    },
    currentView() {
      return this.currentRoute ? this.currentRoute.view : null;
    },
    /**
     * Combine the route-param names with their values from the currentPath to be
     *
     * @return {object}
     * */
    parameters() {
      const matches = this.currentPath.match(this.currentRoute.preg);
      const paramNames = routeToNames(this.currentRoute.route);
      const parameters = {};
      for (let i = 0; i < paramNames.length; i++) {
        parameters[paramNames[i]] = matches[i + 1];
      }
      return parameters;
    },
  },
  methods: {
    clearHash() {
      // Only clear the hash if it one of ours
      if (this.currentView) {
        // Stops scroll but adds a history entry
        window.history.pushState(null, null, ' ');

        this.currentPath = '';
      }
    },
    setHash() {
      const { hash } = window.location;
      const isMatch = this.compiledRoutes.some((route) => hash.match(route.preg));
      // Only change full page hash if a full page modal
      if (this.isFullPage && isMatch) {
        this.currentPath = hash;
      }
      if (!this.isFullPage) {
        this.currentPath = hash;
      }
    },
  },
  mounted() {
    this.setHash();
    window.addEventListener('hashchange', () => {
      this.setHash();
    });
  },
};
</script>
