<template>
  <div class="video-meeting__chats-holder">
    <ul class="video-meeting__chats">
      <TransitionGroup name="t-chat">
        <li
        v-for="chat in chats"
        :key="chat.id"
        class="video-meeting__chat-row"
        >
        <div class="video-meeting__chat-avatar-holder">
          <div v-if="chat.contact">
            <ContactAvatar :contact="chat.contact"/>
          </div>
          <div v-else-if="chat.admin_id" class="contact__avatar default black">
            <i class="fas fa-user-headset"></i>
          </div>
        </div>
        <div class="video-meeting__chat-body-holder">
          <div class="video-meeting__chat-body-message">
            {{ chat.chat_body }}
          </div>
        </div>
      </li>
    </TransitionGroup>
  </ul>
  <div class="video-meeting__new-chat-holder">
    <FrontendTextInput
      id="newMessage"
      key="newMessage"
      name="newMessage"
      :label="__('chat_visible')"
      v-model="newMessage"
      :action-button="true"
      action-button-icon="fas fa-paper-plane"
      :max="250"
      @action-button-clicked="sendChat"
    />
  </div>
</div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';

export default {
  props: {
    initialChats: {
      type: Array,
      default: () => [],
    },
    updateUrl: {
      type: String,
    },
  },
  components: { ContactAvatar },
  data() {
    return {
      chats: this.initialChats,
      newMessage: '',
      timeoutTracker: false,
      hasNewChats: false, // thinking we can use this to trigger the unread chats type notification
    };
  },
  methods: {
    update() {
      axios.get(this.updateUrl)
        .then((response) => {
          this.updateChats(response.data.chats);
        }).then(() => {
          this.updateSoon();
        }).catch(() => {
          // eslint-disable-next-line no-alert
          alert('Please refresh your browser');
        });
    },
    updateSoon() {
      const wait = _.random(3000, 7000);
      this.timeoutTracker = setTimeout(this.update, wait);
    },
    sendChat() {
      this.newMessage = this.newMessage.trim();

      if (this.newMessage.length === 0) {
        return;
      }

      clearTimeout(this.timeoutTracker);
      this.timeoutTracker = false;

      axios.post(this.updateUrl, { new_message: this.newMessage })
        .then((response) => {
          this.newMessage = '';
          this.updateChats(response.data.chats);
        }).then(() => {
          this.updateSoon();
        }).catch(() => {
          // eslint-disable-next-line no-alert
          alert('Please refresh your browser');
        });
    },
    updateChats(newChats) {
      if (newChats.length > this.chats.length) {
        this.hasNewChats = true;
      }
      this.chats = newChats;
    },
  },
  mounted() {
    this.updateSoon();
  },
};
</script>
