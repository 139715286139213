<template>
  <BaseModal
    :title="truncatedCatalogueEntryName"
    height="tall"
    startOpen
  >
    <template v-if="catalogueEntry?.id">
      <div class="base-modal__container--wide" style="margin-bottom: 1rem;">
        <GallerySlider
          v-if="catalogueEntry?.gallery.length > 0"
          :galleryImages="catalogueEntry?.gallery"
        />
      </div>

      <h2 class="catalogue-entry-preview__title">
        {{ truncatedCatalogueEntryName }}
      </h2>

      <template v-if="truncatedDescription">
        <h4> {{ __(`catalogue_product_preview.description`) }} </h4>
        <p>
          {{ truncatedDescription }} <br>
          <a
            v-if="readMoreLinkText"
            href="#"
            @click.prevent="showFullDescription = !showFullDescription"
          >
            {{ readMoreLinkText }}
          </a>
        </p>
      </template>

      <template v-if="catalogueEntry.highlights.length > 0">
        <h4>{{ __(`catalogue_product_preview.highlights`) }}</h4>
        <ul class="catalogue-entry__option-list">
          <li
            v-for="(highlight, highlightIndex) in catalogueEntry.highlights"
            :key="highlightIndex"
          >
            <i class="fa-solid fa-circle-check"></i> {{ highlight.name }}
          </li>
        </ul>
      </template>

      <template v-if="catalogueEntry.links.length > 0">
        <h4 class="catalogue-entry__learn-more-title">
          {{ __(`catalogue_product_preview.learn_more`) }}
        </h4>
        <p class="catalogue-entry__learn-more">
          <template v-for="link in catalogueEntry.links" :key="link.id">
            <i class="me-1" :class="link.fontawesome"></i>
            <a target="_blank" :href="link.url" rel="noopener noreferrer">{{ link.url }}</a>
            <br />
          </template>
        </p>
      </template>

      <div
        v-for="demographicValueGroup, demographicId in visibleDemographicValues"
        :key="demographicId"
      >
        <h5>{{ demographicValueGroup[0].demographic.name }}</h5>
        <div
          v-if="expandedDemographics.has(demographicId)"
          class="catalogue-entry-preview__tags-container"
        >
          <div
            v-for="demographicValue in demographicValueGroup"
            :key="demographicValue.id"
            class="catalogue-entry-preview__tag catalogue-entry-preview__tag--list"
          >
            {{ demographicValue.full_name }}
          </div>
        </div>
        <div v-else class="catalogue-entry-preview__tags-container">
          <div class="catalogue-entry-preview__tag">
            {{ demographicValueGroup[0].full_name }}
          </div>
          <a
            v-if="demographicValueGroup.length > 1"
            class="catalogue-entry-preview__tag catalogue-entry-preview__tag--counter"
            @click="expandedDemographics.add(demographicId)"
          >
            + {{ demographicValueGroup.length - 1 }}
          </a>
        </div>
      </div>
      <br>
      <hr>
      <div class="company-modal__logo-container">
        <img
          v-if="catalogueEntry.company_logo_url"
          class="company-card__logo"
          :src="catalogueEntry.company_logo_url"
          :alt="catalogueEntry.company_display_name + ' logo'"
          @click="goToCompany"
        />
      </div>

      <p v-if="catalogueEntry.company_tagline !== '.'"> {{ catalogueEntry.company_tagline }} </p>

    </template>
    <template v-else>
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template #footer>
      <div class="button-group">
        <a
          v-if="catalogueEntry?.id"
          class="button"
          :href="`/${eventEdition?.slug}/catalogue-entry/${this.catalogueEntry.id}`"
          :target="isIframed ? '_blank' : '_self'"
        >
          {{ __(`catalogue_product_preview.buttons.view`) }}
        </a>
        <a v-if="canBookMeeting"
          class="button"
          :href="`#/companies/${this.catalogueEntry.company_id}/book-meeting/${catalogueEntry?.id}`"
        >
          {{ __(`company.company_preview_modal.book_meeting`) }}
        </a>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import GallerySlider from '~/Components/Frontend/GallerySlider.vue';
import { store } from '../../../Frontend/store';

export default {
  props: {
    catalogueEntryId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errored: false,
      catalogueEntry: {},
      expandedDemographics: new Set(),
      showFullDescription: false,
      canMeetCompany: false,
      visibleDemographicValues: [],
    };
  },
  components: {
    GallerySlider,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    goToCompany() {
      window.location.href = `/${this.eventEdition.slug}/companies/${this.catalogueEntry.company_id}`;
    },
    loadData() {
      axios
        .get(this.previewUrl)
        .then((response) => {
          this.catalogueEntry = response.data.catalogue_entry;
          this.canMeetCompany = response.data.can_meet_company;
          this.visibleDemographicValues = response.data.visible_demographic_values;
        })
        .catch(() => {
          this.errored = true;
        });
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    truncatedCatalogueEntryName() {
      return _.trim(
        _.truncate(this.catalogueEntry?.name, { length: 30, separator: ' ' }),
      );
    },
    truncatedDescription() {
      if (this.catalogueEntry?.description === '.') {
        return null;
      }
      if (this.showFullDescription) {
        return this.catalogueEntry?.description;
      }
      return _.trim(_.truncate(this.catalogueEntry?.description, { length: 250, separator: ' ' }));
    },
    readMoreLinkText() {
      if (this.showFullDescription) {
        // eslint-disable-next-line no-underscore-dangle
        return this.__('catalogue_product_preview.show_less');
      }
      if (this.truncatedDescription !== this.catalogueEntry.description) {
        // eslint-disable-next-line no-underscore-dangle
        return this.__('catalogue_product_preview.read_more');
      }
      return '';
    },
    isIframed() {
      return window.location.pathname.includes('iframe/catalogue-entry-directories');
    },
    previewUrl() {
      if (this.isIframed) {
        return `${window.location.pathname}/catalogue-entries/${this.catalogueEntryId}/preview`;
      }
      return `/${this.eventEdition?.slug}/catalogue-entries/${this.catalogueEntryId}/preview`;
    },
    canBookMeeting() {
      return this.catalogueEntry.company_id
        && !this.catalogueEntry.is_iframed
        && this.eventEdition?.enable_meetings
        && this.eventEdition?.enable_book_contact_meetings_on_company
        && this.canMeetCompany;
    },
  },
};
</script>
