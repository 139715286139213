<template>
  <div>
    <h3>{{ __("video_meeting.message_concierge.header") }}</h3>
  </div>
  <div v-if="messageSent" class="conf-sessions__ask-a-question-success">
    <i class="fa-regular fa-circle-check info-icon info-icon--success"></i>
    <br>
    <h3>{{ __("video_meeting.message_concierge.message_sent") }}</h3>
    <p>{{ __("video_meeting.message_concierge.box_closing") }}</p>
  </div>
  <form v-else @submit.prevent="sendMessage">
    <FrontendTextInput
      type="textarea"
      :max="255"
      placeholder=""
      v-model="message"
      v-model:errors="errors.message"
      :required="true"
    />
    <div class="button-group">
      <button class="button button--primary">
        {{ __('video_meeting.message_concierge.buttons.send') }}
      </button>
      <a class="button button--tertiary" @click="cancelMessage">
        {{ __('video_meeting.message_concierge.buttons.cancel') }}
      </a>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import FrontendTextInput from '~/Components/Frontend/Inputs/FrontendTextInput.vue';
import { modalClose } from '~/modal';

export default {
  name: 'MessageConciergeForm',
  components: { FrontendTextInput },
  data() {
    return {
      message: null,
      errors: {},
      messageSent: false,
    };
  },
  methods: {
    cancelMessage() {
      modalClose();
    },
    sendMessage() {
      axios.post(
        `${window.location.pathname}/send-concierge-message`,
        {
          message: this.message,
        },
      ).then(() => {
        this.messageSent = true;
        setTimeout((modalClose), 6000);
      }).catch((response) => {
        this.errors = response.data?.errors ?? {};
      });
      return false;
    },
  },
};
</script>
