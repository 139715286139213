<template>
  <NotificationBar v-if="canSendInvites" type="dark" :icon="null">
    <PendingLimitReactiveDisplay />
  </NotificationBar>
  <FeLoading v-if="loading" />
  <template v-else>
    <div v-if="statuses.length > 0" class="filter-row  no-scroll-bars">
      <FilterSelect
        v-if="smallLaptopOrSmaller"
        v-model="selectedStatus"
        name="status"
      >
        <FilterSelectOption
          v-for="status in statuses"
          :key="status.status"
          :label="__(`inbox.invites.statusFilters.${status}`)"
          :value="status"
          :badgeNumber="counts[status]"
          :disabled="counts[status] === 0"
        />
      </FilterSelect>
      <FilterRadioGroup
        v-else
        v-model="selectedStatus"
      >
        <FilterRadioOption
          v-for="status in statuses"
          :key="status.status"
          v-model="selectedStatus"
          name="status"
          :label="statusLabels[status]"
          :value="status"
          :badgeNumber="counts[status]"
          :disabled="counts[status] === 0"
        />
      </FilterRadioGroup>
      <div class="filter-group filter-end-align filter-no-scroll">
        <div class="filter-divider"></div>
        <label class="filter-option">
          <i v-if="sort === 'newest'" class="fa-solid fa-arrow-up-wide-short"></i>
          <i v-else class="fa-solid fa-arrow-down-short-wide"></i>
          <select
            class="filter-select"
            name="sort"
            v-model="sort"
          >
            <option value="newest">{{ __('inbox.invites.sort.newest') }}</option>
            <option value="oldest">{{ __('inbox.invites.sort.oldest') }}</option>
          </select>
        </label>
      </div>
    </div>
    <div v-if="invites" class="inbox__results">
      <InboxInvite
        v-for="invite in sortedInvites"
        :key="invite.id"
        :invite="invite"
        :activeContact="activeContact"
        v-bind="invite"
      />
      <div v-if="invites.length === 0" class="inbox__no-results">
        <h1>
          <i class="fa-light fa-circle-check"></i> {{ __('inbox.invites.noResults.title') }}
        </h1>
        <p v-html="
          __('inbox.invites.noResults.message', {
            findPeopleUrl: `/${defaultSearchPage}`
          })"></p>
        <a class="button button--secondary" :href="`/${defaultSearchPage}`">
          {{ __('inbox.invites.findPeople') }}
        </a>
      </div>
    </div>
  </template>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import FilterRadioGroup from '~/Components/Frontend/Core/Filters/FilterRadioGroup.vue';
import FilterRadioOption from '~/Components/Frontend/Core/Filters/FilterRadioOption.vue';
import FilterSelect from '~/Components/Frontend/Core/Filters/FilterSelect.vue';
import FilterSelectOption from '~/Components/Frontend/Core/Filters/FilterSelectOption.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import InboxInvite from '~/Components/Frontend/Networking/Inbox/InboxInvite.vue';
import PendingLimitReactiveDisplay from '~/Components/Frontend/Networking/PendingLimitReactiveDisplay.vue';
import {
  actionRequiredCounts,
  contactInviteCredits,
  store,
  user,
} from '~/Frontend/store';
import NotificationBar from '~/Components/Frontend/NotificationBar.vue';

const listeners = [
  'invites.withdrawn',
];

export default {
  name: 'SentInvitesPage',
  components: [
    FeLoading,
    FilterRadioGroup,
    FilterRadioOption,
    FilterSelect,
    FilterSelectOption,
    InboxInvite,
    NotificationBar,
    PendingLimitReactiveDisplay,
  ],
  props: {
    defaultSearchPage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invites: [],
      loading: false,
      sort: 'newest',
      statuses: [
        'pending',
        'forwarded',
        'declined',
        'withdrawn',
        'expired',
      ],
      selectedStatus: 'pending',
    };
  },
  computed: {
    statusLabels() {
      const labels = {};
      this.statuses.forEach((status) => {
        const txKey = status === 'pending' ? 'sent' : status;
        labels[status] = this.__(`inbox.invites.statusFilters.${txKey}`);
      });
      return labels;
    },
    counts() {
      const counts = {};
      this.statuses.forEach((status) => {
        counts[status] = this.invites.filter((invite) => invite.status === status).length;
      });
      return counts;
    },
    sortedInvites() {
      return _.orderBy(
        this.filteredInvites,
        ['id'],
        this.sort === 'newest' ? 'desc' : 'asc',
      );
    },
    filteredInvites() {
      return this.invites.filter((invite) => invite.status === this.selectedStatus);
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    storeActionRequiredCounts() {
      return actionRequiredCounts;
    },
    canSendInvites() {
      return contactInviteCredits.invite_spent + contactInviteCredits.invite_credits > 0;
    },
  },
  methods: {
    setDefaultStatus() {
      const findNonEmptyStatus = this.statuses.find((status) => this.invites.filter((invite) => invite.status === status).length > 0);
      if (findNonEmptyStatus) {
        this.selectedStatus = findNonEmptyStatus;
      }
    },
    loadData(isEventEmitter = false) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const url = new URL(window.location);
      const params = new URLSearchParams({
        query: 'sentInvites',
        sort: this.sort,
      });
      const ajaxUrl = `${url.pathname}?${params.toString()}`;
      axios.get(`${ajaxUrl}&ajax=true`).then((response) => {
        this.invites = response.data.invites;
        if (!isEventEmitter) {
          this.$nextTick(() => this.setDefaultStatus());
        }
      }).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.emitter.on('*', (type) => {
      if (listeners.includes(type)) {
        this.loadData(true);
      }
    });
    this.loadData();
  },
  setup() {
    const { smallLaptopOrSmaller, mobile } = useBreakpoints();
    return { smallLaptopOrSmaller, mobile };
  },
};

</script>
