<template>
  <FeLoading v-if="loading" />
  <template v-else>
    <div v-if="statuses.length > 0" class="filter-row  no-scroll-bars">
      <FilterSelect
        v-if="smallLaptopOrSmaller"
        v-model="selectedStatus"
        name="status"
      >
        <FilterSelectOption
          v-for="status in statuses"
          :key="status.status"
          :label="__(`inbox.messages.statusFilters.${status}`)"
          :value="status"
          :badgeNumber="counts[status]"
          :disabled="counts[status] === 0"
        />
      </FilterSelect>
      <FilterRadioGroup
        v-else
        v-model="selectedStatus"
      >
        <FilterRadioOption
          v-for="status in statuses"
          :key="status"
          v-model="selectedStatus"
          name="status"
          :label="__(`inbox.messages.statusFilters.${status}`)"
          :value="status"
          :badgeNumber="counts[status]"
          :disabled="counts[status] === 0"
          :badgeType="status === 'all' ? 'informational' : ''"
        />
      </FilterRadioGroup>
      <div class="filter-group filter-end-align filter-no-scroll">
        <div class="filter-divider"></div>
        <label class="filter-option">
          <i v-if="sort === 'newest'" class="fa-solid fa-arrow-up-wide-short"></i>
          <i v-else class="fa-solid fa-arrow-down-short-wide"></i>
          <select
            class="filter-select"
            name="sort"
            v-model="sort"
          >
            <option value="newest">{{ __('inbox.messages.sort.newest') }}</option>
            <option value="oldest">{{ __('inbox.messages.sort.oldest') }}</option>
          </select>
        </label>
      </div>
    </div>
    <div v-if="messages" class="inbox__results">
      <InboxMessage
        v-for="message in sortedMessages"
        :key="message.key"
        :message="message"
        :activeContact="activeContact"
      />
      <div v-if="messages.length === 0" class="inbox__no-results">
        <h1>
          <i class="fa-light fa-circle-check"></i> {{ __('inbox.messages.noResults.title') }}
        </h1>
        <p v-html="
          __('inbox.messages.noResults.message', {
            findPeopleUrl: `/${defaultSearchPage}`
          })"></p>
      </div>
    </div>
  </template>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import FilterRadioGroup from '~/Components/Frontend/Core/Filters/FilterRadioGroup.vue';
import FilterRadioOption from '~/Components/Frontend/Core/Filters/FilterRadioOption.vue';
import FilterSelect from '~/Components/Frontend/Core/Filters/FilterSelect.vue';
import FilterSelectOption from '~/Components/Frontend/Core/Filters/FilterSelectOption.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import InboxMessage from '~/Components/Frontend/Networking/Inbox/InboxMessage.vue';
import { actionRequiredCounts, store, user } from '~/Frontend/store';

const listeners = [
  'inboxMessages.read',
  'inboxMessages.new',
];

export default {
  name: 'InboxMessages',
  components: {
    FeLoading,
    FilterRadioGroup,
    FilterRadioOption,
    FilterSelect,
    FilterSelectOption,
    InboxMessage,
  },
  props: {
    defaultSearchPage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      loading: false,
      sort: 'newest',
      statuses: [
        'all',
        'unread',
      ],
      selectedStatus: 'all',
    };
  },
  computed: {
    counts() {
      return {
        all: this.messages.length,
        unread: this.messages.filter((message) => !message.read).length,
      };
    },
    sortedMessages() {
      return _.orderBy(
        this.filteredMessages,
        ['created_at'],
        this.sort === 'newest' ? 'desc' : 'asc',
      );
    },
    filteredMessages() {
      if (this.selectedStatus === 'all') {
        return this.messages;
      }
      return this.messages.filter((message) => !message.read);
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
    storeActionRequiredCounts() {
      return actionRequiredCounts;
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const params = new URLSearchParams({
        query: 'messages',
        sort: this.sort,
      });

      const ajaxUrl = `${url.pathname}?${params.toString()}`;

      axios.get(`${ajaxUrl}&ajax=true`).then((response) => {
        this.messages = response.data.messages;
      }).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.emitter.on('*', (type) => {
      if (listeners.includes(type)) {
        this.loadData();
      }
    });
    this.loadData();
  },
  setup() {
    const { smallLaptopOrSmaller, mobile } = useBreakpoints();
    return { smallLaptopOrSmaller, mobile };
  },
};

</script>
