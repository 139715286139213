<template>

  <BaseModal
    height="tall"
    startOpen
    ref="modal"
    :notifications="notifications"
  >
    <template #header>
      <ContactModalHeader
        v-if="contact.id"
        :key="contactId"
        :contact="contact"
      />
    </template>

    <template v-if="submitting || loading">
      <h1 class="ball-scale-ripple-multiple">
        <div></div> <div></div> <div></div>
      </h1>
    </template>

    <template v-else>

      <template v-if="!optedInMeetable">
        <h3>
          <i class="far fa-triangle-exclamation"></i>
          {{ __('meeting_invite.opt_in_meetings.title') }}
        </h3>
        <p>{{ __('meeting_invite.opt_in_meetings.opt_in_meetings_text') }}</p>

        <button @click="optInToMeetings" class="button">
          {{ __('meeting_invite.opt_in_meetings.accept') }}
        </button>
        <button @click="modalClose" class="button button--secondary">
          {{ __('meeting_invite.opt_in_meetings.decline') }}
        </button>
      </template>

      <template v-else-if="!complete">

        <section v-if="contactInviteCredits.limitReached()" style="text-align: center;">
          <i class="fa-regular fa-circle-info info-icon"></i>
          <h1> {{ __('meeting_invite.pending_invites.limit_reached.title') }} </h1>
          <p>
            {{ __('meeting_invite.pending_invites.limit_reached.body') }}
          </p>
          <p class="meeting-invite-form__pending_invites"
            v-html="__('meeting_invite.pending_invites.remaining',
              {
                invite_spent: contactInviteCredits.invite_spent,
                invite_credits: contactInviteCredits.invite_credits,
              }
            )"
          />
        </section>

        <MeetingInviteNotificationBar
          v-else-if="status.message"
          :status="status"
          :contact="contact.id"
        />

        <form
          v-if="status.is_blocked === false"
          action="contacts/invite"
          method="post"
          autocomplete="off"
          class="expanded-contact-card__invite-form"
          ref="sendInviteForm"
        >
          <FrontendTextInput
            id="invite-message"
            name="message"
            v-model="message"
            :label="__('meeting_invite.send_invite.header')"
            :placeholder="__('meeting_invite.send_invite.message_placeholder')"
            type="textarea"
            :error="errorMessage"
            :max="1000"
            :rows="7"
            :generate-ai-url="eventEdition.enable_ai_meeting_invite ? `/${this.eventEdition.slug}/contacts/${this.contact.id}/generate-invite-text` : ''"
            required
          />
        </form>
      </template>

      <template v-else>
        <section class="meeting-invite-form__success">
          <h1>
            <i class="fas fa-circle-check info-icon--success"></i>
            {{ __('meeting_invite.send_invite.success.title') }}
          </h1>
          <p>
            {{ __('meeting_invite.send_invite.success.message' ) }}<br>
            <a :href="updateAvailabilityUrl">
              {{ __('meeting_invite.send_invite.success.update_availability' ) }}
            </a>
          </p>
          <NotificationBar type="gray" icon="" center>
          <span v-html="__('meeting_invite.pending_invites.remaining',
              {
                invite_spent: contactInviteCredits.invite_spent,
                invite_credits: contactInviteCredits.invite_credits,
              }
            )
          "></span>
          </NotificationBar>
        </section>

      </template>
    </template>

    <template #footer>
      <div class="button-group">
        <template v-if="!complete">
          <a class="button button--secondary" @click.prevent="modalClose">
            {{ __('meeting_invite.send_invite.buttons.cancel') }}
          </a>

          <button
            type="button"
            class="button button--primary"
            :disabled="loading || submitting || errored || !optedInMeetable"
            @click.prevent="sendInvite"
          >
            {{ __('meeting_invite.send_invite.buttons.send') }}
          </button>
        </template>

        <template v-else>
          <a
            class="button button--secondary"
            :href="sentInvitesUrl"
          >
            {{ __('meeting_invite.send_invite.buttons.view_sent_invites') }}
          </a>
          <button class="button" @click="modalClose">
            {{ __('meeting_invite.send_invite.buttons.done') }}
          </button>
        </template>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import axios from 'axios';
import { store, contactInviteCredits } from '../../../Frontend/store';
import FrontendTextInput from '../Inputs/FrontendTextInput.vue';

export default {
  name: 'SendMeetingInviteModal',
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contact: {},
      message: '',
      loading: false,
      submitting: false,
      complete: false,
      errored: false,
      status: {},
      optedInMeetable: false,
      errorMessage: null,
      contactInviteCredits,
      inviteId: null,
      notifications: [],
    };
  },
  components: {
    FrontendTextInput,
  },
  inject: [
    'eventEditionManager',
  ],
  methods: {
    loadData() {
      this.loading = true;
      axios
        .get(`/${this.eventEdition?.slug}/contacts/${this.contactId}`)
        .then((response) => {
          this.optedInMeetable = response.data.opted_in_meetable;
          this.contact = response.data.contact;
          this.loading = false;
          this.status = response.data.contact.meeting_invite_status.status;
          if (!this.contact.policy.can_meet) {
            this.errored = true;
            this.notifications.push({
              time: Date.now(),
              type: 'error',
              // eslint-disable-next-line no-underscore-dangle
              message: this.__('meeting_invite.send_invite.errors.cannot_send_invite'),
              icon: 'fa-circle-info',
            });
          }
        })
        .catch((response) => {
          this.errored = true;
          this.errorMessage = response?.data?.message;
          this.notifications.push({
            time: Date.now(),
            type: 'error',
            message: "Couldn't load contact information.",
            icon: 'fa-circle-info',
          });
          this.loading = false;
        });
    },
    sendInvite() {
      if (!this.$refs.sendInviteForm.checkValidity()) {
        this.$refs.sendInviteForm.reportValidity();
        return false;
      }
      this.submitting = true;
      axios.post(`/${this.eventEdition.slug}/contacts/${this.contact.id}/invite`, {
        message: this.message,
      })
        .then((response) => {
          this.emitter.emit('invites.sent', {
            contactId: response.data.invite.recipient.id,
          });
          this.inviteId = response.data.invite.id;
          contactInviteCredits.setInviteSpent(response.data.invite_spent);
          this.complete = true;
        })
        .catch(({ response }) => {
          this.errorMessage = response?.data?.message;
        })
        .then(() => {
          this.submitting = false;
        });
      return true;
    },
    optInToMeetings() {
      axios.post(`/${this.eventEdition.slug}/opt-in-meetings`)
        .then((response) => {
          this.optedInMeetable = response.data.meetable;
        })
        .catch(({ response }) => {
          this.errorMessage = response?.data?.message;
        })
        .then(() => {
          this.submitting = false;
        });
    },
    modalClose() {
      this.$refs.modal.close();
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    updateAvailabilityUrl() {
      return `/${this.eventEdition.slug}/my-schedule#/update-availability`;
    },
    sentInvitesUrl() {
      return `/${this.eventEdition.slug}/inbox?initialTab=sent_invites`;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
